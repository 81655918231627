import { useState, useMemo, useCallback } from 'react'
import { debounce } from "../utilities/debounce";

export type Suggestion = {
    displayName: string;
    itemNumber: string;
    url: string;
}

export type SuggestionCategories = {
    spareParts: Suggestion[];
    usedParts: Suggestion[];
    machineModels: Suggestion[];
    sparePartsTotal: number;
    usedPartsTotal: number;
    machineModelsTotal: number;
}

const getEmptySuggestionCategoriesObj = () => ({
    spareParts: [],
    usedParts: [],
    machineModels: [],
    sparePartsTotal: 0,
    usedPartsTotal: 0,
    machineModelsTotal: 0,
})

const preloadState = (window as any).app.preloadState.headerSearchBox;

export const useCategorySuggestions = () => {
    const [value, setValue] = useState<string>("");
    const [results, setResults] = useState<SuggestionCategories>(getEmptySuggestionCategoriesObj());

    const process = useCallback(async (value: string) => {
        if (value.trim() === "") {
            setResults(getEmptySuggestionCategoriesObj());
            return;
        }
        const response = await fetch(`${preloadState.searchResultApi}?searchQuery=${value}`, {
            headers: {
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        const data = await response.json();
        setResults(data);
    }, []);

    const debouncedProcess = useMemo(() => debounce(process, 200), [process]);

    const onFocus = useCallback(() => {
        document.getElementsByTagName("body")[0].classList.add("blurring");
        process(value);
    }, [value]);

    const search = useCallback(() => {
        window.location.assign(`${preloadState.searchResultPage}?query=${value}`)
    }, [value]);

    const onKeyUp = useCallback((e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            search();
        }
    }, [search]);

    const onBlur = useCallback(() => {
        setTimeout(() => {
            setResults(getEmptySuggestionCategoriesObj());
            document.getElementsByTagName("body")[0].classList.remove("blurring");
        }, 200);
    }, []);

    const onInput = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        setValue(value);
        debouncedProcess(value);
    }, [debouncedProcess]);

    return {
        results,
        onFocus,
        onBlur,
        onKeyUp,
        value,
        onInput,
        search
    };
}


export const useSuggestions = () => {
    const preloadState = (window as any).app.preloadState.headerSearchBox;
    const [value, setValue] = useState<string>("");
    const [results, setResults] = useState<Suggestion[]>([]);

    const onFocus = () => {
        document.getElementsByTagName("body")[0].classList.add("blurring");
    }

    const onKeyUp = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            window.location.assign(`${preloadState.searchResultPage}?query=${value}`)
        }
    }

    const onBlur = () => {
        setTimeout(() => {
            setResults([]);
            document.getElementsByTagName("body")[0].classList.remove("blurring");
        }, 200);
    }

    const process = useMemo(() => debounce(async (value: string) => {
        if (value === "") {
            setResults([]);
            return;
        }
        const response = await fetch(`${preloadState.searchResultApi}?searchQuery=${value}`, {
            headers: {
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        const data = await response.json();
        setResults(data);
    }, 200), []);

    const onInput = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        setValue(value);
        process(value);
    }, [process]);

    return {
        results,
        onFocus,
        onBlur,
        onKeyUp,
        value,
        onInput
    };
}
