import { ReactNode, useState, useSyncExternalStore } from "react";

export enum ButtonResult {
    Ok = "Ok",
    Yes = "Yes", 
    No = "No",
    Cancel = "Cancel",
}

export enum ButtonType {
    Primary = "primary", 
    Outlined = "outlined",
    Link = "linked"
}

export interface Button<T extends ButtonResult> {
    label: string;
    result: T;
    type: ButtonType;
    enabled?: boolean;
    className?: string;
    visible?: boolean;
}

export enum PopupSize {
    Small = 'small',
    Large = 'large',
    ExtraVertical = 'vertical-large',
    ExtraLarge = 'extra-large'
}

export const popup = <T extends ButtonResult>(title: string, content: ReactNode, buttons: Button<T>[], size: PopupSize = PopupSize.Small, popUpClass?: string, footer?: { content?: ReactNode, reverse?: boolean }) => {
    return new Promise<T>(resolve => {
        window.popupButtonPrechecks = new Map<ButtonResult, () => (boolean | Promise<boolean>)>();
        window.popupCallback(title, content, buttons, size, popUpClass, { content: footer?.content, reverse: footer?.reverse }, resolve as (_: ButtonResult) => void);
    });
}

type PopupState = {
    open: boolean,
    title: string,
    content: ReactNode,
    buttons: Button<ButtonResult>[],
    size: PopupSize,
    footerContent?: ReactNode,
    footerReverse?: boolean,
    resolve: (result: ButtonResult) => void,
    popUpClass?: string;
};

window.popupState = {
    open: false,
    title: "",
    content: "" as ReactNode,
    buttons: [] as Button<ButtonResult>[],
    size: PopupSize.Small,
    popUpClass: undefined,
    footerContent: undefined,
    footerReverse: undefined,
    resolve: (_: ButtonResult) => { }
};

window.popupStateCallback ??= [];

const setState = (newState: PopupState) => {
    window.popupState = newState;
    window.popupStateCallback.forEach(c => {
        try {
            c();
        } catch (e) {
            console.error(e);
        }
    });
}

window.popupCallback = (title, content, buttons, size, popUpClass, footer, resolve) => {
    setState({ open: true, title, content, buttons, size, popUpClass, footerContent: footer?.content, footerReverse: footer?.reverse, resolve });
}

window.popupButtonCallback = (index, enabled) => {
    setState({ ...window.popupState, buttons: window.popupState.buttons.map((b, i) => i === index ? ({ ...b, enabled }) : { ...b }) });
}

export const usePopup = () => {

    const state = useSyncExternalStore(
        (change) => {
            window.popupStateCallback.push(change);
            return () => {
                window.popupStateCallback = window.popupStateCallback.filter(c => c !== change);
            }
        },
        () => window.popupState
    );

    return {
        ...state,
        close: async (result: ButtonResult) => {
            const checkFunction = window.popupButtonPrechecks.get(result);
            if (checkFunction) {
                const checkResult = await checkFunction();
                if (!checkResult) return;
            }
            state.resolve(result);
            setState({
                open: false,
                title: "",
                content: "",
                buttons: [] as Button<ButtonResult>[],
                size: PopupSize.Small,
                popUpClass: undefined,
                footerContent: undefined,
                footerReverse: undefined,
                resolve: (_: ButtonResult) => { }
            })
        }
    };
}

export const setPopupButton = (buttonIndex: number, enabled: boolean) => {
    window.popupButtonCallback?.(buttonIndex, enabled);
}

export const usePopupButtonPrecheck = (buttonType: ButtonResult, check: () => (boolean | Promise<boolean>)) => {
    window.popupButtonPrechecks.set(buttonType, check);
};

declare global {
    interface Window {
        popupCallback: (title: string, content: ReactNode, buttons: Button<ButtonResult>[], size: PopupSize, popUpClass: string | undefined, footer: { content?: ReactNode, reverse?: boolean }, resolve: (_: ButtonResult) => void) => void
        popupButtonCallback: (buttonIndex: number, enabled: boolean) => void;
        popupButtonPrechecks: Map<ButtonResult, () => (boolean | Promise<boolean>)>;
        popupStateCallback: (() => void)[];
        popupState: PopupState;
    }
}