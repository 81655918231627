import { useMemo, useState, useSyncExternalStore } from "react";
import { CheckboxFacet, FacetData, RangeFacet, urlSubscribe } from "./SparePartsList";
import { ButtonResult, ButtonType, popup, PopupSize } from "../../utilities";
import cn from 'classnames';
import { InputCheckbox, InputRange } from "../Inputs";
import * as styles from './DesktopFacets.module.scss';

const translationState = (window as any).app.preloadState.translation;

export const DesktopFacet = ({ facet, reload }: { facet: FacetData, reload: () => void }) => {
    const [open, setOpen] = useState(true);

    const showInfoPopup = (ev: React.MouseEvent) => {
        if (!facet.infoPopUp) return;
        ev.stopPropagation();
        popup(
            facet.infoPopUp.title,
            <div dangerouslySetInnerHTML={{ __html: facet.infoPopUp.description }}></div>,
            [
                { label: translationState["sparePartVariationButtonOptions.close"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ],
            PopupSize.Small
        );
    }

    const ActualFacet = () => {
        switch (facet.type) {
            case 'checkbox':
                return <FacetCheckboxes facet={facet} reload={reload} />
            case 'range':
                return <FacetRange facet={facet} reload={reload} />
        }
    }
    return <div>
        <h3 className="heading--s" onClick={() => setOpen(!open)}>
            <span>
                {facet.header}
                {(facet.infoPopUp != null) && <span className={styles.popupIcon} onClick={showInfoPopup}></span>}
            </span>
            <span className={cn(styles.chevron, open ? styles.chevronUp : styles.chevronDown)}></span>
        </h3>
        <hr />
        {open && ActualFacet()}
    </div>;
}

const FacetCheckboxes = ({ facet, reload }: { facet: CheckboxFacet, reload: () => void }) => {

    const urlData = useSyncExternalStore(
        urlSubscribe,
        () => new URLSearchParams(location.search).get(facet.name)
    );

    const selectedValues = useMemo(() => {
        if (urlData == null) return new Set<string>();
        return new Set<string>(urlData.split(',').filter(s => !!s));
    }, [urlData]);

    const toggleFacet = (value: string) => {
        if (selectedValues.has(value)) {
            selectedValues.delete(value);
        } else {
            selectedValues.add(value);
        }
        const params = new URLSearchParams(location.search);
        if (selectedValues.size == 0) {
            params.delete(facet.name);
        } else {
            params.set(facet.name, Array.from(selectedValues).join(','));
        }
        window.history.replaceState(null, "", location.pathname + '?' + params.toString());
        reload();
    }

    return <>{
        facet.options.map(option => <InputCheckbox key={option.value} label={option.label} name={facet.name} disabled={option.disabled} checked={selectedValues.has(option.value)} onChange={() => toggleFacet(option.value)} />)
    }</>;
}

const FacetRange = ({ facet, reload }: { facet: RangeFacet, reload: () => void }) => {

    const urlData = useSyncExternalStore(
        urlSubscribe,
        () => new URLSearchParams(location.search).get(facet.name)
    );

    const selectedValue = useMemo(() => {
        if (urlData == null) return { low: facet.min, high: facet.max };
        const [low, high] = urlData.split(',');
        const data = { low: parseInt(low, 10), high: parseInt(high, 10) };
        if (data.low > data.high) return { low: facet.min, high: facet.max };
        return data;
    }, [urlData, facet.min, facet.max]);

    const toggleFacet = (value: { low: number, high: number }) => {
        const params = new URLSearchParams(location.search);
        params.set(facet.name, value.low + ',' + value.high);
        window.history.replaceState(null, "", location.pathname + '?' + params.toString());
        reload();
    };

    return <InputRange
        max={facet.max}
        min={facet.min}
        value={selectedValue}
        onBlur={toggleFacet}
    />
}
