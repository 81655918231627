import * as styles from "./app.module.scss";
import cn from "classnames";
import { useEffect, useState } from "react";
import { ButtonResult, ButtonType, InputPhone, InputText, PopupSize, popup, setPopupButton, useChange, usePopupButtonPrecheck, useValidation } from "ui";

const translations = (window as any).app.preloadState.translation;
const pdp = (window as any).app.preloadState.pdp;

function App() {

    const [showCopied, setShowCopied] = useState(false);

    const copyLink = async () => {
        await window.navigator.clipboard.writeText(window.location.href);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 1000);
    }

    const showMailPopup = async () => {
        let emailData: Email | null = null;
        const result = await popup(
            translations["productDetailsPageButtons.sendMailPopUpTitle"],
            <SendMailPopUpBody callback={e => emailData = e} />,
            [
                { label: translations["productDetailsPageButtons.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translations["productDetailsPageButtons.send"], result: ButtonResult.Ok, type: ButtonType.Primary }

            ],
            PopupSize.Small
        );
        if (result !== ButtonResult.Ok) {
            return;
        }

        const mailResult = await fetch("/api/customersupport/mail", {
            method: 'POST',
            body: JSON.stringify(emailData),
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });

        if (!mailResult.ok)
        {
            console.error(await mailResult.text());
        }
    }

    return <div className={styles.buttonsWrapper}>
        <button onClick={showMailPopup} className={cn("btn btn--outlined--icon", styles.iconButton, styles.mailButton)}>{translations["productDetailsPageButtons.contactSeller"]}</button>
        {
            showCopied
                ? <button className={cn("btn btn--outlined--icon", styles.iconButton, styles.copiedButton)}>{translations["productDetailsPageButtons.copiedLink"]}</button>
                : <button onClick={copyLink} className={cn("btn btn--outlined--icon", styles.iconButton, styles.linkButton)}>{translations["productDetailsPageButtons.copyLink"]}</button>
        }
    </div>;
}

type Email = {
    name: string;
    mailBody: string;
    emailAddress: string;
    phoneNumber: string;
    url: string;
    articleNo: string;
};

function SendMailPopUpBody({ callback }: { callback : (email: Email) => void }) {
    const regEx: string = window.app.preloadState.emailValidationRegEx;
    const emailValidationRegEx = new RegExp(regEx);

    const [name, setName] = useState<string>("");
    const [mailBody, setMailBody] = useState<string>("");
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");

    const nameValidator = useValidation(translations["productDetailsPageButtons.customerNameIsInvalid"]);
    const mailBodyValidator = useValidation(translations["productDetailsPageButtons.customerMailBodyIsInvalid"]);
    const emailValidator = useValidation(translations["productDetailsPageButtons.customerEmailIsInvalid"], emailValidationRegEx);
    const phoneValidator = useValidation(translations["productDetailsPageButtons.customerPhoneNumberIsInvalid"]);

    usePopupButtonPrecheck(ButtonResult.Ok, () => {
        const obj = {
            name,
            mailBody,
            emailAddress,
            phoneNumber
        };
        const nameValidation = nameValidator.revalidate(obj);
        const emailValidation = emailValidator.revalidate(obj);
        const mailBodyValidation = mailBodyValidator.revalidate(obj);
        const phoneValidation = phoneValidator.revalidate(obj);
        return nameValidation && emailValidation && mailBodyValidation && phoneValidation;
    });

    useChange(() => {
        callback({
            name,
            mailBody,
            emailAddress,
            phoneNumber,
            url: window.location.href,
            articleNo: pdp.articleNo
        });
    }, [phoneNumber, emailAddress, name, mailBody]);

    const { onBlur, error } = mailBodyValidator('mailBody');

    return <div className={styles.contactSellerMailPopUp}>
        <InputText
            name="name"
            onChange={ev => setName(ev.target.value)}
            label={translations["productDetailsPageButtons.nameLabel"]}
            value={name}
            {...nameValidator('name')}
        />
        <InputText
            name="emailAddress"
            label={translations["productDetailsPageButtons.emailAddressLabel"]}
            value={emailAddress}
            onChange={ev => setEmailAddress(ev.target.value)}
            {...emailValidator('emailAddress')}
        />
        <InputPhone
            name="phoneNumber"
            label={translations["productDetailsPageButtons.phoneNumberLabel"]}
            value={phoneNumber}
            onChange={setPhoneNumber}
            {...phoneValidator('phoneNumber')}
        />
        <div>
            <textarea rows={5}
                placeholder={translations["productDetailsPageButtons.mailBodyLabel"]}
                value={mailBody}
                onChange={(ev) => setMailBody(ev.target.value)}
                onBlur={onBlur}/>
            {error != undefined && <span className={styles.textAreaError}>{error}</span>}
        </div>
    </div>
}

export default App;
