import { ReactNode, useEffect, useMemo, useState, useSyncExternalStore } from "react";
import { SparePartsSortField, urlSubscribe } from "./SparePartsList";
import { InputRadio } from "../Inputs";
import cn from 'classnames';
import * as styles from './Sorter.module.scss';

const loggedIn = window.app.preloadState.isLoggedIn;
const usedPartsEnabled = window.app.preloadState.usedPartsEnabled;
const translationState = (window as any).app.preloadState.translation;

export const Sorter = ({ reload, children }: { reload: () => void, children?: ReactNode }) => {
    const [open, setOpen] = useState(false);

    const urlData = useSyncExternalStore(
        urlSubscribe,
        () => {
            const params = new URLSearchParams(location.search);
            return (params.get('sort') ?? SparePartsSortField.Best) + ';' + (params.get('sortDirection') ?? 'desc');
        }
    );

    const sorting = useMemo(() => {
        const [sort, sortDirection] = urlData.split(';');
        switch (sort) {
            case SparePartsSortField.Best:
            case SparePartsSortField.Name:
            case SparePartsSortField.Created:
            case SparePartsSortField.Price:
                return [sort, sortDirection === 'asc'] as [SparePartsSortField, boolean];
            default:
                return [SparePartsSortField.Best, sortDirection === 'asc'] as [SparePartsSortField, boolean];
        }
    }, [urlData]);

    const toggleSorting = (value: SparePartsSortField, ascending: boolean) => {
        const params = new URLSearchParams(location.search);
        params.set('sort', value);
        params.set('sortDirection', ascending ? 'asc' : 'desc');
        window.history.replaceState(null, "", location.pathname + '?' + params.toString());
        reload();
    }

    useEffect(() => {
        document.addEventListener("click", (ev) => {
            const filterRadioContainer = document.getElementById("SorterRadioContainer")!;
            const filterRadioButton = document.getElementById("SorterRadioButton")!;
            const target = (ev.target as HTMLDivElement);
            if (filterRadioContainer && !filterRadioContainer.contains(target) && !filterRadioButton.contains(target)) {
                setOpen(false);
            }
        });
    }, []);

    const getLabelFor = (field: SparePartsSortField, ascending: boolean): string => {
        switch (field) {
            case SparePartsSortField.Best:
                return translationState['sorting.best'];
            case SparePartsSortField.Name:
                return ascending ? translationState['sorting.nameAscending'] : translationState['sorting.nameDescending'];
            case SparePartsSortField.Created:
                return ascending ? translationState['sorting.createdAscending'] : translationState['sorting.createdDescending'];
            case SparePartsSortField.Price:
                return ascending ? translationState['sorting.priceAscending'] : translationState['sorting.priceDescending'];
        }
    }

    const radioHelper = (field: SparePartsSortField, ascending: boolean) => ({
        label: getLabelFor(field, ascending),
        name: "sortingMethod",
        checked: sorting[0] === field && sorting[1] === ascending,
        onChange: () => toggleSorting(field, ascending)
    });

    return <div className={styles.sorterContainer}>
        {children ? children : <div></div>}

        <button id="SorterRadioButton" className={styles.sortByButton} onClick={() => setOpen(o => !o)}>{translationState["sorting.sortBy"]}: <span className="font-weight-bold">{getLabelFor(sorting[0], sorting[1])}</span><span className={cn(styles.chevron, open ? styles.chevronUp : styles.chevronDown)}></span></button>
        {open && <div className={styles.sorterRadioContainer} id="SorterRadioContainer">
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Best, false)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Name, true)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Name, false)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Created, true)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Created, false)} />
            </div>
            {loggedIn && usedPartsEnabled && <>
                <div className={styles.sorting}>
                    <InputRadio {...radioHelper(SparePartsSortField.Price, true)} />
                </div>
                <div className={styles.sorting}>
                    <InputRadio {...radioHelper(SparePartsSortField.Price, false)} />
                </div>
            </>}
        </div>}
    </div>
}