// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SPUqGa3i98M7resRP3kN{display:flex;flex-direction:column;gap:8px;align-items:flex-start}.hexlLaEXhQA95h0gS_hQ{vertical-align:sub}`, ""]);
// Exports
export var wrapper = `SPUqGa3i98M7resRP3kN`;
export var iconStyling = `hexlLaEXhQA95h0gS_hQ`;
export default ___CSS_LOADER_EXPORT___;
