import React, { ReactNode, useEffect, useMemo, useRef, useState, useSyncExternalStore, useTransition } from "react"
import { ButtonResult, ButtonType, InputCheckbox, InputRadio, InputRange, PopupSize, popup } from "ui";
import { Inventories } from "../Inventories/Inventories";
import * as styles from './SparePartsList.module.scss';
import { Item } from "./Item";
import { SearchResultItem } from "../../types";
import cn from 'classnames';
import { Sorter } from "./Sorter";
import { DesktopFacet } from "./DesktopFacets";
import { MobileFacets } from "./MobileFacets";

const translationState = (window as any).app.preloadState.translation;
const showInventoryPopup = (item: SearchResultItem, ev: React.MouseEvent) => {
    ev.preventDefault();
    popup(
        translationState["inventoryPopup.stockStatusAtFacility"],
        <Inventories itemCode={item.code} itemDisplayName={item.displayName} />,
        [
            { label: translationState["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
        ],
        PopupSize.Large
    );
}

export type FacetValue = {
    key: string;
    values: string[];
}

type SparePartsListProps = {
    items: SearchResultItem[];
    total: number;
    facets: FacetData[];
    pageCallback: (page: number, facets: FacetValue[], sort: SparePartsSortField, sortAscending: boolean) => Promise<SearchResultItem[]>;
    children?: ReactNode;
}

export const SparePartsList = ({ items, facets, total, pageCallback, children }: SparePartsListProps) => {

    const [firstItems, setFirstItems] = useState([items, 0] as [SearchResultItem[], number]);

    const numberOfDivs = firstItems[0].length > 0 ? Math.ceil(total / firstItems[0].length) : 1;

    const mapping = Array.from(Array(numberOfDivs - 1).keys())

    const callback = async (page: number) => {
        const params = new URLSearchParams(location.search);

        const facetValues = facets
            .filter(facet => params.has(facet.name))
            .map(facet => ({
                key: facet.name,
                values: params.get(facet.name)!.split(',')
            }));

        const sortValue = params.get('sort') ?? SparePartsSortField.Best;
        const sortDirectionValue = params.get('sortDirection') ?? 'desc';

        let sort: SparePartsSortField;
        let sortAscending: boolean;

        switch (sortValue) {
            case SparePartsSortField.Best:
            case SparePartsSortField.Name:
            case SparePartsSortField.Created:
            case SparePartsSortField.Price:
                [sort, sortAscending] = [sortValue, sortDirectionValue === 'asc'] as [SparePartsSortField, boolean];
                break;
            default:
                [sort, sortAscending] = [SparePartsSortField.Best, sortDirectionValue === 'asc'] as [SparePartsSortField, boolean];
                break;
        }

        return await pageCallback(page, facetValues, sort, sortAscending);
    }

    const reloadData = () => {
        setTimeout(() => {
            urlChanged.forEach(c => c());
            callback(1).then(data => setFirstItems(p => [data, p[1] + 1]));
        }, 10);
    };

    return <>
        <Sorter reload={reloadData} children={children} />
        <div className={styles.sparePartItemsModule}>
            <div className={styles.sparePartsFacets}>
                {facets.map(facet => <DesktopFacet facet={facet} key={facet.name} reload={reloadData} />) }
            </div>
            <div className={styles.sparePartsFacetsMobile}>
                <MobileFacets facets={facets} reload={reloadData} />
            </div>
            <div className={styles.sparePartsList}>
                <div className={styles.sparePartItems}>
                    {firstItems[0].map(item => <Item
                        key={item.code + '_' + firstItems[1]}
                        item={item}
                        showInventoryPopup={showInventoryPopup}
                    />)}
                </div>
                {mapping.map((x) => {
                    return <SparePartsObserver key={x + '_' + firstItems[1]} page={x + 2} pageCallback={callback} />
                })}
            </div>
        </div>
    </>
}

const SparePartsObserver = ({ page, pageCallback }: { page: number, pageCallback: (page: number, ) => Promise<SearchResultItem[]> }) => {
    const [items, setItems] = useState<SearchResultItem[]>([]);
    const loaded = useRef(false);

    const observer = useMemo(() => {
        const classOptions = {
            root: document.rootElement,
            rootMargin: "200px 0px",
            threshold: 0,
        };

        const callback = async (entries: IntersectionObserverEntry[]) => {
            if (entries[0].isIntersecting && !loaded.current) {
                const data = await pageCallback(page);
                setItems(data);
                loaded.current = true;
            }
        }

        return new IntersectionObserver(callback, classOptions);
    }, []);

    useEffect(() => {
        let target = document.querySelector(`#list_${page}`);
        observer.observe(target!);
    }, [])

    return <>
        {items.length ?
            <div className={styles.sparePartItems}>
                {items.map(item => <Item
                    key={item.code}
                    item={item}
                    showInventoryPopup={showInventoryPopup}
                />)}
            </div>
            :
            <div id={`list_${page}`} className={styles.itemsPlaceholder}>

            </div>
        }
        </>
}

type InfoPopUp = {
    title: string;
    description: string;
};

export type BaseFacet = {
    name: string;
    header: string;
    infoPopUp: InfoPopUp | null;
};

export type CheckboxFacet = BaseFacet & {
    type: 'checkbox';
    options: { label: string; value: string, disabled: boolean }[];
};

export type RangeFacet = BaseFacet & {
    type: 'range';
    min: number;
    max: number;
};

export type FacetData = CheckboxFacet | RangeFacet;

let urlChanged: (() => void)[] = [];

export const urlSubscribe = (callback: () => void) => {
    urlChanged.push(callback);
    return () => {
        urlChanged = urlChanged.filter(c => c != callback);
    }
}

export enum SparePartsSortField {
    Best = 'Best',
    Name = 'Name',
    Created = 'Created',
    Price = 'Price'
}
