// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qcRCmMGm3KTu6bvtujD1{display:block}.Y_KOdkw3RKtc6WZspVhJ{cursor:pointer;text-decoration:underline}.TLSwiP0lCIBfJI5r4iQk{color:#434B4FA6}.TY7JeModBDdZHG2GZtQ9{gap:4px;display:flex;flex-direction:column;flex-wrap:wrap}.fqAUMyS33dLL7zyH8yXy{vertical-align:sub;display:inline-block;margin:0 0 0 3px}.fqAUMyS33dLL7zyH8yXy:after{vertical-align:sub}`, ""]);
// Exports
export var wrapper = `qcRCmMGm3KTu6bvtujD1`;
export var machineResultsLink = `Y_KOdkw3RKtc6WZspVhJ`;
export var machineResultsSecondary = `TLSwiP0lCIBfJI5r4iQk`;
export var machineSpacer = `TY7JeModBDdZHG2GZtQ9`;
export var infoIconStyles = `fqAUMyS33dLL7zyH8yXy`;
export default ___CSS_LOADER_EXPORT___;
