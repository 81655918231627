import { useEffect, useState } from "react";
import { ButtonResult, setPopupButton, usePopupButtonPrecheck } from "../../utilities";
import { InputSelect, InputText } from "../Inputs";
import { Address, Country } from "../../types";
import * as styles from "./NewAddressPopup.module.scss";
import { useValidation } from "../../hooks";

type NewAddressPopupProps = {
    updateAddress: (a: Address) => void;
    countries: Country[];
    manageDeliveryAddressesLink: string;
};

const translations = window.app.preloadState.translation;

export const NewAddressPopup = ({ updateAddress, countries, manageDeliveryAddressesLink }: NewAddressPopupProps) => {
    const fieldRequiredValidator = useValidation(translations["sharedTranslations.fieldIsRequired"]);

    const [address, setAddress] = useState<Address>({
        name: "",
        streetAndStreetNumber: "",
        zipCode: "",
        city: "",
        countryRegionId: "",
    });

    useEffect(() => {
        updateAddress(address);
    }, [address]);

    usePopupButtonPrecheck(ButtonResult.Ok, () => fieldRequiredValidator.revalidate(address));

    const updater = (prop: keyof Address) => ({
        name: prop,
        value: address[prop],
        onChange: (ev: React.ChangeEvent<HTMLInputElement> | string) => {
            setAddress({
                ...address,
                [prop]: typeof ev === 'string' ? ev : ev.target.value
            });
        }
    });

    return (<div>
        <p>{translations["checkout.deliveryOptions.NewAddressPopUp.Description.BeforeLink"]}
            <a href={manageDeliveryAddressesLink}>{translations["checkout.deliveryOptions.NewAddressPopUp.Description.Link"]}</a>
            {translations["checkout.deliveryOptions.NewAddressPopUp.Description.AfterLink"]}
        </p>
        <div className={styles.newAddressPopUpInputs}>
            <InputText label={translations["checkout.deliveryOptions.NewAddressPopUp.CompanyNameOrNameOfRecipient"]} {...updater('name')} {...fieldRequiredValidator('name')} />
            <InputText label={translations["checkout.deliveryOptions.NewAddressPopUp.PostalAddress"]} {...updater('streetAndStreetNumber')} {...fieldRequiredValidator('streetAndStreetNumber')} />
            <InputText label={translations["checkout.deliveryOptions.NewAddressPopUp.ZIPCode"]} {...updater('zipCode')} {...fieldRequiredValidator('zipCode')} />
            <InputText label={translations["checkout.deliveryOptions.NewAddressPopUp.Place"]} {...updater('city')} {...fieldRequiredValidator('city')} />
            <InputSelect label={translations["checkout.deliveryOptions.NewAddressPopUp.Country"]} placeholder={translations["checkout.deliveryOptions.NewAddressPopUp.CountryPlaceholder"]} {...updater('countryRegionId')} {...fieldRequiredValidator('countryRegionId')} options={countries.map(c => ({ label: c.displayName, value: c.twoLetterCode }))} />
        </div>
    </div>);
}