import { InfoPopUpIcon } from "ui";
import { TCatalog } from "../../../types";
import { VisitedMachine } from "../../VisitedMachine/VisitedMachine";
import * as styles from "./type.module.scss";

type TypeProps = {
    machines: TCatalog[];
    selectedCode?: string | null;
    onClick: (machine: TCatalog) => void;
    heading: string;
    hoveredTextDescription: string | null;
};

export const Type = ({ machines, onClick, heading, hoveredTextDescription }: TypeProps) => {
    return (
        <>
            {heading && <p className="description firstLetterCapital" id="machine-selection-types">{heading} {<InfoPopUpIcon additionalClass={ styles.iconStyling} body={hoveredTextDescription} />}</p>}
            <div className={styles.wrapper}>
                {machines.map((machine) => (
                    <VisitedMachine key={machine.code} machine={machine} clickHandler={onClick.bind(null, machine)} />
                ))}
            </div>
      </>
   
  );
};
