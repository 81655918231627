import * as styles from './MobileFacets.module.scss';
import cn from 'classnames';
import { CheckboxFacet, FacetData, RangeFacet, urlSubscribe } from './SparePartsList';
import { Fragment, useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { InputCheckbox, InputRange } from '../Inputs';
import { RangeValue } from '../Inputs/InputRange';

export const MobileFacets = ({ facets, reload }: { facets: FacetData[], reload: () => void }) => {

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [willClose, setWillClose] = useState(false);

    const [selectedFacet, setSelectedFacet] = useState<FacetData | null>(null);

    const urlData = useSyncExternalStore(
        urlSubscribe,
        () => {
            const obj: Record<string, string> = {};
            new URLSearchParams(location.search).forEach((v, k) => obj[k] = v);
            return JSON.stringify(obj);
        }
    );

    const [values, setValues] = useState<Record<string, string>>(JSON.parse(urlData))

    useEffect(() => {
        const csIcon = document.getElementById('CustomerSupportIcon');
        if (!csIcon) return;
        if (selectedFacet) {
            csIcon.classList.add('d-none');
        } else {
            csIcon.classList.remove('d-none');
        }
    }, [selectedFacet]);

    useEffect(() => {
        if (!isFilterOpen) {
            setValues(JSON.parse(urlData));
        }
    }, [isFilterOpen, urlData])

    const clearFacet = () => {
        setWillClose(true);
        setTimeout(() => {
            setWillClose(false);
            setSelectedFacet(null);
        }, 500);
    }

    const resetFilters = () => {
        const params = new URLSearchParams(location.search);
        facets.forEach(facet => params.delete(facet.name));
        window.history.replaceState(null, "", location.pathname + '?' + params.toString());
        setValues({})
        reload();
    }

    const applyFilters = () => {
        const params = new URLSearchParams(location.search);
        Object.entries(values).forEach(([name, value]) => params.set(name, value));
        window.history.replaceState(null, "", location.pathname + '?' + params.toString());
        reload();
        setIsFilterOpen(false);
    }

    return <>
        <button onClick={() => setIsFilterOpen(!isFilterOpen)} className={cn(styles.filterButton, isFilterOpen && styles.isOpen)}>
            {isFilterOpen
                ? "Close filter"
                : "Filter"}
        </button>
        <div className={cn(styles.filterContainer, isFilterOpen && styles.isOpen)}>
            <hr />
            <div className={styles.filterFacets}>
                {facets.map(facet => <Facet facet={facet} key={facet.name} selectFacet={() => setSelectedFacet(facet)} />)}
            </div>
            <div className={styles.endButtons}>
                <button className="btn btn--outlined" onClick={resetFilters}>Rensa filter</button>
                <button className="btn btn--primary" onClick={applyFilters}>Apply</button>
            </div>
        </div>
        {<div className={cn(styles.filterDrawerBlur, !!selectedFacet && !willClose && styles.open)} onClick={clearFacet}>
            <div>
                <FacetComponent facet={selectedFacet} close={clearFacet} values={values} setValues={setValues} />
            </div>
        </div>}
    </>
}

const Facet = ({ facet, selectFacet }: { facet: FacetData, selectFacet: () => void }) => {
    return <div onClick={selectFacet} className={styles.facet}>
        <span>{facet.header}</span>
        <span className={styles.chevrons}></span>
    </div>;
}

const FacetComponent = ({ facet, close, values, setValues }: { facet: FacetData | null, close: () => void, values: Record<string, string>, setValues: (v: Record<string, string>) => void }) => {
    const ActualFacet = ({ facet }: { facet: FacetData }) => {
        switch (facet.type) {
            case 'checkbox': {
                const value = new Set<string>((values[facet.name] ?? "").split(',').filter(s => !!s));
                return <FacetCheckboxes facet={facet} facetValue={value} setFacetValue={set => {
                    setValues({
                        ...values,
                        [facet.name]: Array.from(set).join(',')
                    })
                }} />
            }
            case 'range': {
                const [low, high] = values[facet.name]?.split(',') ?? ["" + facet.min, "" + facet.max ];
                let value = { low: parseInt(low, 10), high: parseInt(high, 10) };
                if (value.low > value.high) {
                    value = { low: facet.min, high: facet.max };
                }
                return <FacetRange facet={facet} facetValue={value} setFacetValue={range => {
                    setValues({
                        ...values,
                        [facet.name]: range.low + ',' + range.high
                    })
                }} />
            }
    }
    }
    return <div className={styles.container} onClick={ev => ev.stopPropagation()}>
        {!!facet && <>
            <div className={styles.facetHeader}>
                <span>{facet.header}</span>
                <span className={styles.close} onClick={close}></span>
            </div>
            <hr/>
            <ActualFacet facet={facet}/>
        </>}
    </div>
}

const FacetCheckboxes = ({ facet, facetValue, setFacetValue }: { facet: CheckboxFacet, facetValue: Set<string>, setFacetValue: (set: Set<string>) => void }) => {

    const selectedValues = useMemo(() => new Set<string>(facetValue), [facetValue]);

    const toggleFacet = (value: string) => {
        if (selectedValues.has(value)) {
            selectedValues.delete(value);
        } else {
            selectedValues.add(value);
        }
        setFacetValue(selectedValues);
    }

    return <div className={styles.checkboxContainer}>
        {facet.options.map((option, index) => <Fragment key={option.value}>
            {index > 0 && <hr/> }
            <InputCheckbox label={option.label} disabled={option.disabled} name={facet.name} checked={selectedValues.has(option.value)} onChange={() => toggleFacet(option.value)} />
        </Fragment>)}
    </div>
}

const FacetRange = ({ facet, facetValue, setFacetValue }: { facet: RangeFacet, facetValue: RangeValue, setFacetValue: (value: RangeValue) => void }) => {
    return <div className={styles.rangeContainer}>
        <InputRange max={facet.max} min={facet.min} value={facetValue} onBlur={setFacetValue} />
    </div>
}