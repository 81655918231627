import cn from 'classnames';
import * as styles from './Inputs.module.scss';
import { useMemo, useRef, useState } from 'react';

export type RangeValue = {
    low: number;
    high: number;
};

type InputRangeProps = {
    label?: string;
    value: RangeValue;
    min: number;
    max: number;
    onChange?: (value: RangeValue) => void;
    onBlur?: (value: RangeValue) => void;
    error?: string | undefined;
    disabled?: boolean;
    className?: string | undefined;
    suffix?: string;
}

const clampValue = (value: RangeValue, min: number, max: number): RangeValue => {
    value = { ...value };
    if (value.high < value.low) {
        const temp = value.high;
        value.high = value.low;
        value.low = temp;
    }
    value.high = Math.min(value.high, max);
    value.low = Math.max(value.low, min);
    return value;
}

export const InputRange = ({ label, value, min, max, disabled = false, onChange, onBlur, error, className, suffix }: InputRangeProps) => {
    const slider = useRef<HTMLDivElement>(null);
    const [internalValue, setInternalValue] = useState(clampValue(value, min, max));

    const calculateNewValue = (x: number): [number, number] => {
        const boundingBox = slider.current?.getBoundingClientRect() ?? { left: 0, width: 1 };
        const leftOffset = x - boundingBox.left;
        const valuePercent = leftOffset / boundingBox.width;
        return [
            valuePercent * (max - min) + min,
            16 * (max - min) / boundingBox.width
        ];
    }

    const handleBall = (moveCallback: (ev: MouseEvent) => void) => {
        document.body.classList.add('grabbedBall');
        document.addEventListener('mousemove', moveCallback);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', moveCallback);
            document.body.classList.remove('grabbedBall');
            setInternalValue(v => {
                onBlur?.(v);
                return v;
            });
        }, { once: true });
    };

    const handleLowBall = () => {
        const moveCallback = (ev: MouseEvent) => {
            const [newValue, ballValue] = calculateNewValue(ev.clientX);
            setInternalValue(v => {
                const newV = {
                    ...v,
                    low: Math.trunc(Math.min(Math.min(Math.max(newValue, min), max), v.high - ballValue))
                };
                onChange?.(newV);
                return newV;
            });
        };
        handleBall(moveCallback);
    };

    const handleHighBall = () => {
        const moveCallback = (ev: MouseEvent) => {
            const [newValue, ballValue] = calculateNewValue(ev.clientX);
            setInternalValue(v => {
                const newV = {
                    ...v,
                    high: Math.trunc(Math.max(Math.min(Math.max(newValue, min), max), v.low + ballValue))
                };
                onChange?.(newV);
                return newV;
            });
        };
        handleBall(moveCallback);
    }

    const left = useMemo(() => (100 * (internalValue.low - min) / (max - min)) + '%', [internalValue, max, min]);
    const width = useMemo(() => (100 * (internalValue.high - internalValue.low) / (max - min)) + '%', [internalValue, max, min]);
    const right = useMemo(() => (100 * (internalValue.high - min) / (max - min)) + '%', [internalValue, max, min]);

    const addSuffix = (value: number) => suffix ? `${value} ${suffix}` : value;

    return (<div className={cn("form-text", styles.sliderInput, className, disabled && styles.disabled)}>
        {label && <label>{label}</label>}
        <div className={styles.sliderGray} ref={slider}></div>
        <div className={styles.sliderBlack} style={{ left, width }}></div>
        <div className={styles.ball} style={{ left }} onMouseDown={handleLowBall}></div>
        <div className={styles.ball} style={{ left: right }} onMouseDown={handleHighBall}></div>
        <input type="text" className={styles.lowInput} readOnly value={addSuffix(internalValue.low)} />
        <input type="text" className={styles.highInput} readOnly value={addSuffix(internalValue.high)} />
        {error && <span className="form-error">{error}</span>}
    </div>);
}