import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import * as styles from './FinancePopUpBody.module.scss';
import { FinanceOptions, MachinePaymentMode } from '../../types';
import { InputCheckbox, InputNumber, InputSelect, InputText } from '../Inputs';
import { ButtonResult, ButtonType, debounce, delay, setPopupButton, updateMachineCart, useMachineCartChange, usePopup } from '../../utilities';
import { UpdateFinanceOptions } from "finance-button/src/types";
import { updateMachineCartItem } from '../../hooks';
import { Loader } from '../Loader/Loader';

const translations = (window as any).app.preloadState.translation;
const userId: string = (window as any).app.preloadState.currentUserId;

type FinanceApiData = {
    marketId: string;
    currency: string;
    unitPriceAmount?: number;
    unitPriceTaxValue?: number,
    unitPriceTaxValueWithCurrency?: string,
    financeOptionsDescription: string,
    estimatedAnnualPercentageRate: number,
    availableTermsOptions: number[],
    availableDownpaymentOptions: number[],
    downpaymentPercentage: number,
    insuranceRate: number,
    insuranceCostWithCurrency: string,
    shipmentCost: number,
    shipmentCostWithCurrency: string,
    isInsuranceVisible: boolean,
    quantity: number,
    selectedTerm: number | null,
    selectedDownpayment: number | null
};

type FinancePopupData = {
    MarketId: string;
    Currency: string;
    UnitPriceAmount?: number;
    UnitPriceTaxValue?: number;
    UnitPriceTaxValueWithCurrency?: string;
    FinanceOptionsDescription: string,
    EstimatedAnnualPercentageRate: number,
    AvailableTermsOptions: { label: string, value: number }[],
    SelectedTerm: number,
    Downpayment: number,
    AvailableDownpaymentOptions: { label: string, value: number }[],
    SelectedDownpayment: number,
    IncludeTaxesAndFees: boolean,
    ShipmentCost: number,
    ShipmentCostWithCurrency: string,
    isInsuranceVisible: boolean,
    Quantity: number,
    InsuranceRate: number,
};

type FinanceOptionsChoicesData = Partial<Record<string, FinanceOptionsUserChoices>>;

type FinanceOptionsUserChoices = Partial<Record<string, FinanceOptionsMachineChoices>>;

type FinanceOptionsMachineChoices = Partial<{
    SelectedDownpayment: number;
    SelectedTerm: number;
    IncludeTaxesAndFees: boolean;
}>;


type Button = {
    label: string;
    result: ButtonResult;
    type: ButtonType;
    animate?: boolean;
}

const getButtons = (readonly: boolean, paymentInCart: MachinePaymentMode | undefined): Button[] => {
    if (readonly) {
        return [
            { label: translations["financeOptions.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined }
        ]
    }
    switch (paymentInCart) {
        case MachinePaymentMode.Direct:
            return [
                { label: translations["financeOptions.addFinancing"], result: ButtonResult.Ok, type: ButtonType.Primary, animate: true },
                { label: translations["financeOptions.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined }
            ]
        case MachinePaymentMode.Monthly:
            return [
                { label: translations["financeOptions.update"], result: ButtonResult.Ok, type: ButtonType.Primary, animate: true },
                { label: translations["financeOptions.remove"], result: ButtonResult.No, type: ButtonType.Outlined, animate: true },
                { label: translations["financeOptions.cancel"], result: ButtonResult.Cancel, type: ButtonType.Link },
            ]
        case undefined:
            return [
                { label: translations["financeOptions.addToCart"], result: ButtonResult.Ok, type: ButtonType.Primary, animate: true },
                { label: translations["financeOptions.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined }
            ]
    }
}

export function FinancePopUpBody({serialNumber, readonly = false}: {
    serialNumber: string,
    readonly?: boolean
}) {
    const [state, setState] = useState<FinancePopupData | null>(null);
    const [taxInfoVisible, setTaxInfoVisible] = useState(false);
    const [paymentInCart, setPaymentInCart] = useState<MachinePaymentMode | undefined>();

    useMachineCartChange(useCallback(cart => {
        const newPaymentInCart = cart.lineItems.find(m => m.code == serialNumber)?.paymentMode;
        setPaymentInCart(newPaymentInCart);
        setButtons(getButtons(readonly, newPaymentInCart));
    }, [readonly]));

    const [buttons, setButtons] = useState(getButtons(readonly, paymentInCart));

    const popUpInnerState = usePopup();

    const performAction = async (button: Button) => {
        if (!state) return;
        if (button.animate) {
            setButtons(
                buttons.map(b => b.label === button.label
                    ? { ...button, label: "loading" }
                    : b
                )
            );
        }

        let financeOptions: UpdateFinanceOptions = {
            code: serialNumber,
            quantity: state.Quantity,
            financeOptionsInput: {
                marketId: state.MarketId,
                currency: state.Currency,
                price: (state.UnitPriceAmount || 0) * (state.Quantity || 1),
                downPaymentOption: state.SelectedDownpayment,
                yearlyInterestRatePercentage: state.EstimatedAnnualPercentageRate,
                numberOfMonthsOnTerm: state.SelectedTerm,
                insuranceRate: state.InsuranceRate,
                shipmentCost: state.ShipmentCost,
                includeShipmentCost: state.IncludeTaxesAndFees,
            }
        };
        if (button.result === ButtonResult.Ok) {

            const updateResponse = await fetch(`/api/machinesales/cart/items/update-finance-options`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify(financeOptions),
                method: "POST"
            });

            if (!updateResponse.ok) {
                console.error(await updateResponse.text());
            }
            if (paymentInCart === undefined) {
                await updateMachineCartItem(serialNumber ?? "", 1);
            } else {
                await updateMachineCart();
            }
        } else if (button.result === ButtonResult.No) {
            const updateResponse = await fetch(`/api/machinesales/cart/items/remove-finance-options`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify({ code: serialNumber ?? "" }),
                method: "DELETE"
            });

            if (!updateResponse.ok) {
                console.error(await updateResponse.text());
            }
            await updateMachineCart();
        }
        await popUpInnerState.close(button.result);
    }

    const getLocalChoices = () => {
        try {
            const choicesJson: string | null = window.localStorage.getItem("financeOptionsChoices");
            if (!choicesJson) return {};
            const choices: FinanceOptionsChoicesData = JSON.parse(choicesJson);
            if (!choices || typeof choices !== 'object') return {};
            const userChoices: FinanceOptionsUserChoices | undefined = choices[userId];
            if (!userChoices || typeof userChoices !== 'object') return {};
            const machineChoice: FinanceOptionsMachineChoices | undefined = userChoices[serialNumber];
            if (!machineChoice || typeof machineChoice !== 'object') return {};
            return machineChoice;
        } catch {
            return {};
        }
    };

    useEffect(() => {
        if (!state) return;
        const choicesJson: string | null = window.localStorage.getItem("financeOptionsChoices");
        let choices: FinanceOptionsChoicesData;
        try {
            choices = JSON.parse(choicesJson ?? "{}");
            if (!choices || typeof choices !== 'object') {
                choices = {};
            }
        } catch {
            choices = {};
        }
        let userChoices: FinanceOptionsUserChoices = choices[userId] ?? {};
        if (!userChoices || typeof userChoices !== 'object') {
            userChoices = {};
        }
        let machineChoices: FinanceOptionsMachineChoices = userChoices[serialNumber] ?? {};
        if (!machineChoices || typeof machineChoices !== 'object') {
            machineChoices = {};
        }

        machineChoices = {
            SelectedDownpayment: state.SelectedDownpayment,
            SelectedTerm: state.SelectedTerm,
            IncludeTaxesAndFees: state.IncludeTaxesAndFees
        };

        userChoices[serialNumber] = machineChoices;
        choices[userId] = userChoices;

        if (machineChoices.SelectedDownpayment === 0 && machineChoices.SelectedTerm === 0 && machineChoices.IncludeTaxesAndFees === true) {
            delete userChoices[serialNumber];
            if (Object.keys(userChoices).length === 0) {
                delete choices[userId];
            }
        }

        window.localStorage.setItem("financeOptionsChoices", JSON.stringify(choices));
    }, [state]);

    const [resultState, setResultState] = useState<FinanceOptions>({
        financedAmount: 0,
        financedAmountWithCurrency: "",
        monthlyAnnuityPayment: 0,
        monthlyPayment: 0,
        monthlyPaymentWithCurrency: "",
        totalCost: 0,
        firstInstalment: 0,
        firstInstalmentWithCurrency: "",
        insuranceCost: 0,
        insuranceCostWithCurrency: "",
        totalTax: 0,
        totalTaxWithCurrency: "",
    });

    const debouncedRecalculate = useMemo(() => debounce(async (state: FinancePopupData) => {
        const response = await fetch('/api/machinesales/financeOptions/recalculate', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                marketId: state.MarketId,
                currency: state.Currency,
                price: (state.UnitPriceAmount || 0) * (state.Quantity || 1),
                downPaymentOption: state.SelectedDownpayment,
                yearlyInterestRatePercentage: state.EstimatedAnnualPercentageRate,
                numberOfMonthsOnTerm: state.SelectedTerm,
                insuranceRate: state.IncludeTaxesAndFees ? (state.InsuranceRate || 0) : 0,
                shipmentCost: state.ShipmentCost || 0,
                includeShipmentCost: state.IncludeTaxesAndFees,
            }),
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const data: FinanceOptions = await response.json();
        setResultState(data);


    }, 500), []);

    const toggleInfo = () => {
        setTaxInfoVisible(!taxInfoVisible);
    }

    useEffect(() => {
        const localChoices = getLocalChoices();
        (async () => {
            const response = await fetch(`/api/machinesales/financeOptions/${serialNumber}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if (!response.ok) {
                throw new Error(await response.text());
            }
            const data: FinanceApiData = await response.json();
            setState({
                MarketId: data.marketId,
                Currency: data.currency,
                UnitPriceAmount: data.unitPriceAmount,
                UnitPriceTaxValue: data.unitPriceTaxValue,
                UnitPriceTaxValueWithCurrency: data.unitPriceTaxValueWithCurrency,
                FinanceOptionsDescription: data.financeOptionsDescription,
                EstimatedAnnualPercentageRate: data.estimatedAnnualPercentageRate,
                AvailableTermsOptions: data.availableTermsOptions && data.availableTermsOptions.map(term => ({
                    label: term + ' ' + translations["financeOptions.months"],
                    value: term
                })),
                SelectedTerm: localChoices.SelectedTerm ?? (data.selectedTerm ? data.selectedTerm : data.availableTermsOptions[0]),
                AvailableDownpaymentOptions: data.availableDownpaymentOptions.map(downpayment => ({
                    label: downpayment + ' %',
                    value: downpayment
                })),
                SelectedDownpayment: localChoices.SelectedDownpayment ?? (data.selectedDownpayment ? data.selectedDownpayment : data.availableDownpaymentOptions[0]),
                Downpayment: (data.unitPriceAmount || 0) * data.availableDownpaymentOptions[0] / 100,
                InsuranceRate: data.insuranceRate,
                IncludeTaxesAndFees: localChoices.IncludeTaxesAndFees ?? true,
                ShipmentCost: data.shipmentCost,
                ShipmentCostWithCurrency: data.shipmentCostWithCurrency,
                isInsuranceVisible: data.isInsuranceVisible,
                Quantity: data.quantity,
            });
        })();
    }, []);


    useEffect(() => {
        if (state && state.Downpayment >= 0 && state.Downpayment < (state.UnitPriceAmount ?? 0)) {
            !readonly && setPopupButton(0, true);
            debouncedRecalculate(state);
        } else !readonly && setPopupButton(0, false);
    }, [state]);

    const changeState = (name: keyof FinancePopupData) => (value: FinancePopupData[typeof name]) => {
        if (state) {
            setState({
                ...state,
                [name]: value
            });
        }
    };

    return <div>
        <div className={styles.row}>
            <div>
                {state?.FinanceOptionsDescription ?? ""}
            </div>
        </div>

        <div className={styles.row}>
            <div>
                <InputSelect
                    name="downpayment"
                    label={translations["financeOptions.downpayment"]}
                    value={state?.SelectedDownpayment ?? 0}
                    disabled={readonly}
                    onChange={changeState('SelectedDownpayment')}
                    options={state?.AvailableDownpaymentOptions ?? []}
                />
            </div>
            <div>
                <InputSelect
                    name="term"
                    label={translations["financeOptions.term"]}
                    value={state?.SelectedTerm ?? 0}
                    disabled={readonly}
                    onChange={changeState('SelectedTerm')}
                    options={state?.AvailableTermsOptions ?? []}
                />
            </div>
        </div>
        <div className={styles.row}>
            <div>
                <InputNumber
                    name="apr"
                    label={translations["financeOptions.apr"]}
                    value={state?.EstimatedAnnualPercentageRate ?? 0}
                    disabled={true}
                >
                    <div className="user-select-none">％</div>
                </InputNumber>
            </div>
        </div>

        <div className={styles.row}>
            <div>
                <InputText
                    name="financedAmount"
                    label={translations["financeOptions.financedAmount"]}
                    value={resultState?.financedAmountWithCurrency}
                    disabled={true}/>
            </div>
            <div>
                <InputText
                    name="firstPaymentCost"
                    label={translations["financeOptions.firstPaymentCost"]}
                    value={resultState?.firstInstalmentWithCurrency}
                    disabled={true}/>
            </div>
        </div>
        <div className={cn(styles.row, styles.moreMargin)}>
            <InputCheckbox name="includeTaxesAndFees" label={translations["financeOptions.includeTaxesAndFees"]}
                           disabled={readonly} checked={state?.IncludeTaxesAndFees}
                           onChange={changeState('IncludeTaxesAndFees')}/>
        </div>

        <div className={cn(styles.row, styles.alignToRight)}>
            <div>{translations["financeOptions.salesPrice"]}</div>
            <div>{resultState.priceWithCurrency}</div>
        </div>
        <div className={cn(styles.row, styles.alignToRight)}>
            <div>{translations["financeOptions.estimatedLeasePayment"]}</div>
            <div>{resultState.financedAmountWithCurrency}</div>
        </div>
        <div className={cn(styles.row, styles.alignToRight)}>
            <div>{translations["financeOptions.firstInstallment"]}</div>
            <div>{resultState.firstInstalmentWithCurrency}</div>
        </div>
        <div className={cn(styles.row, styles.monthlyCost, styles.alignToRight)}>
            <div>{translations["financeOptions.monthlyCost"]}</div>
            <div>{resultState.monthlyPaymentWithCurrency}</div>
        </div>
        {(state?.IncludeTaxesAndFees === true) && (<>
            <div className={cn(styles.row, styles.alignToRight)}>
                <div>{translations["financeOptions.shippingFees"]}</div>
                {state?.ShipmentCost == 0 ?
                    <div>{state?.ShipmentCostWithCurrency}</div>
                    :
                    <div>{translations["financeOptions.incoming"]}</div>
                }
            </div>
            {state?.isInsuranceVisible && <div>
                <div className={cn(styles.row, styles.alignToRight)}>
                    <div>{translations["financeOptions.insurance"]}</div>
                    <div>{resultState?.insuranceCostWithCurrency}</div>
                </div>
            </div>}
            <div className={cn(styles.row, styles.alignToRight)}>
                <div className={styles.taxInfo}>{translations["financeOptions.taxes"]} <span onClick={toggleInfo} className={styles.iconForHoveredText}></span></div>
                <div>{resultState.totalTaxWithCurrency}</div>
            </div>
            <div className={cn(styles.row, styles.alignToRight)}>
                <div className={cn(styles.taxInfoNote, taxInfoVisible && styles.visibleTaxInfoNote)}>
                {translations["financeOptions.taxInfoNote"]}
                </div>
            </div>
        </>)}
        <hr className={ styles.footerBorderTop} />
            {buttons.length > 0 &&
            <div className={cn(styles.modalFooter)}>
                <div>
                    {popUpInnerState.footerContent}
                </div>
                <div className={cn("modal__buttons", styles.buttonsContainer)}>
                    {buttons.map(button => {
                        const buttonBody = button.label === 'loading'
                            ? <Loader />
                            : button.label;
                        const loaderClass = button.label === 'loading'
                            ? styles.buttonLabelLoadingState
                            : ''
                        switch (button.type) {
                            case ButtonType.Primary:
                                return <a key={button.result} onClick={() => performAction(button)} className={cn("btn btn--primary", loaderClass)}>{buttonBody}</a>
                            case ButtonType.Outlined:
                                return <a key={button.result} onClick={() => performAction(button)} className={cn("btn btn--outlined", loaderClass)}>{buttonBody}</a>
                            case ButtonType.Link:
                                return <a key={button.result} onClick={() => performAction(button)} className={cn("btn btn--linked", loaderClass)}>{buttonBody}</a>
                        }
                    })}
                </div>
            </div>
            }
        </div>
}
