import { MouseEvent, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import * as styles from "./app.module.scss";
import { Item } from "./components/Item";
import { DeliveryOptions, DeliveryMethods } from "./components/DeliveryOptions";
import {
    updateCartItem,
    popup,
    ButtonResult,
    ButtonType,
    ensureCart,
    updateCartKitItem,
    partition,
    updateRemanAndDepositCartItem
} from "ui";
import { BillingInfo } from "./components/BillingInfo/BillingInfo";
import { BillingInformation, Cart, LineItem, OrderClass } from "ui/src/types";
import { Kit } from "./components/Kit";
import { OrderClassOptions } from "./components/OrderClassOptions";
import { RemanufacturedItem } from "./components/RemanufacturedItem";
import { RFQNote } from "./components/RFQ/RFQNote";
import { RfqSplitOrder } from "./components/RFQ/RfqSplitOrder";
import { AlternativeSummaryLines, SummaryLines } from "./components/SummaryLines";

const checkoutData = (window as any).app.preloadState.checkout;
const translations = window.app.preloadState.translation;

let billingInfo: Partial<BillingInformation> | null = null;
let orderClass: string = checkoutData.orderClasses.find((oc: OrderClass) => oc.isSelected)?.value ?? "-1";

function App() {
    ensureCart();
    const [deliveryInfoValid, setDeliveryInfoValid] = useState(false);

    const [cart, setCart] = useState<Cart>({
        isMatomoEnabled: false,
        lineItems: checkoutData.parts,
        kits: checkoutData.kits,
        summary: checkoutData.summary,
        customerInformation: checkoutData.billingSummary,
        deliveryMethods: checkoutData.deliveryMethods,
        orderClasses: checkoutData.orderClasses,
        machineInfo: checkoutData.machineInfo,
        selectedDeliveryOption: checkoutData.selectedDeliveryOption,
        validationMessages: checkoutData.validationMessages,
        branchContactInfo: checkoutData.branchContactInfo,
        rfq: checkoutData.rfq,
        orderClassMachineSuggestions: checkoutData.orderClassMachineSuggestions,
        deliveryMethodSubTypes: checkoutData.deliveryMethodSubTypes
    });

    const [nonRemanItems, remanGroups] = useMemo(() => separateRemanGroups(cart.lineItems), [cart.lineItems]);

    const [termsAndConditionsValue, setTermsAndConditionsValue] = useState(false);

    const reloadCart = async () => {
        const response = await fetch('/api/cart', {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const data: Cart = await response.json();

        window.cart.update();

        setCart({
            ...cart,
            ...data
        });
    }

    const onItemChangeClickHandler = async (code: string, quantity: number) => {
        await updateCartItem(code, quantity);
        await reloadCart();
    };

    const onKitChangeClickHandler = async (code: string, quantity: number, bundleCode: string, kitCode: string, serialNumber: string | null) => {
        await updateCartKitItem(code, quantity, bundleCode, kitCode, serialNumber);
        await reloadCart();
    };

    const onRemanChangeClickHandler = async (remanCode: string, depositCode: string, quantity: number) => {
        await updateRemanAndDepositCartItem(remanCode, depositCode, quantity);
        await reloadCart();
    }

    const onItemRemoveClickHandler = async (code: string, callback: () => void) => {
        await updateCartItem(code, 0);
        await reloadCart();
        callback();
    };

    const onKitRemoveClickHandler = async (code: string, bundleCode: string, kitCode: string, serialNumber: string | null, callback: () => void) => {
        await updateCartKitItem(code, 0, bundleCode, kitCode, serialNumber);
        await reloadCart();
        callback();
    };

    const onRemanRemoveClickHandler = async (remanCode: string, depositCode: string, callback: () => void) => {
        await updateRemanAndDepositCartItem(remanCode, depositCode, 0);
        await reloadCart();
        callback();
    }

    const removeAll = async (e: MouseEvent<HTMLAnchorElement>, type: 'item' | 'kit') => {
        e.preventDefault();

        const uppercaseType = type === 'item' ? 'Item' : 'Kit';
        const result = await popup(
            translations[`checkout.deleteAll${uppercaseType}sHeader`],
            <div>{translations[`checkout.deleteAll${uppercaseType}sContent`]}</div>,
            [
                { type: ButtonType.Primary, label: translations[`checkout.deleteAll${uppercaseType}sYes`], result: ButtonResult.Yes },
                { type: ButtonType.Outlined, label: translations[`checkout.deleteAll${uppercaseType}sNo`], result: ButtonResult.No }
            ]
        );

        if (result !== ButtonResult.Yes)
            return;

        const response = await fetch(`/api/cart/${type}-lines`, {
            body: null,
            method: "DELETE",
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });

        const data = await response.json();

        if (!data.isSuccess) {
            console.error(JSON.stringify(data))
        } else {
            await reloadCart();
        }
    };

    useEffect(() => {
        if (cart.lineItems.length == 0 && cart.kits.length == 0) {
            window.location.reload();
        }
    }, [cart]);

    const showFreightPopup = () => {
        popup(
            checkoutData.freightCostTitle,
            checkoutData.freightCostDescription,
            [
                { label: translations["checkout.freightCostButton"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ]
        );
    }

    const createOrder = async () => {

        const invalidChecks = [
            {
                invalid: !termsAndConditionsValue,
                message: translations["checkout.uncheckedTermsAndConditions"]
            },
            {
                invalid: !deliveryInfoValid,
                message: translations["checkout.validDeliveryInformationRequired"]
            },
            {
                invalid: (cart.orderClasses.length > 0 && orderClass === "-1"),
                message: translations["checkout.orderClassRequired"]
            }
        ]

        if (invalidChecks.some(ic => ic.invalid)) {
            setCart({
                ...cart,
                validationMessages: [
                    ...cart.validationMessages
                        .filter(vm => invalidChecks.every(ic => ic.message !== vm.message)),
                    ...(invalidChecks.filter(ic => ic.invalid).map(ic => ({ message: ic.message, redirectUri: "" })))
                ]
            });
            return;
        }
        const updateResponse = await fetch(`/api/cart/update`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(billingInfo),
            method: "POST"
        });

        if (!updateResponse.ok) {
            throw new Error(await updateResponse.text());
        }
        else {
            const response = await fetch(`/api/order/create`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify({}),
                method: "POST"
            });
            const data = await response.json();
            if (!data.isSuccess) {
                setCart(data.cartApiModel);
            } else {
                location.assign(data.orderConfirmationPageUrl);
            }
        }
    }

    const toggleValue = () => {
        setTermsAndConditionsValue(!termsAndConditionsValue);
    }

    const noteExists = useMemo(() => !!cart.rfq.note?.trim(), [cart.rfq.note]);

    const willRfqBeCreated = useMemo(
        () => cart.rfq.hasItemWithoutPrice || noteExists,
        [cart.rfq.hasItemWithoutPrice, noteExists]
    )

    const hasMoreThanOneProduct = (cart.lineItems?.filter((item: LineItem) => !item.isRemanItem).length ?? 0) + (cart.kits?.length ?? 0) > 1;

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>{translations["checkout.title"]}</h1>
            {cart.orderClasses.length > 0 &&
                <div className={classnames(styles.content, styles.spacing)}>
                    <OrderClassOptions
                        orderClasses={cart.orderClasses}
                        machineSuggestions={cart.orderClassMachineSuggestions.filter(machine => machine.salesModel)}
                        selectedMachineModel={cart.machineInfo ? cart.machineInfo.machineModel : null}
                        selectedSerialNumber={cart.machineInfo ? cart.machineInfo.serialNumber : null}
                        serialNumberRegex={checkoutData.serialNumberRegex}
                        onOrderClassChange={(oc) => {
                            orderClass = oc;
                            reloadCart();
                        }} />
                </div>
            }
            <div className={styles.content}>
                {cart.lineItems.length > 0 && <div className={styles.sectionWrapper}>
                    <p className={styles.heading}>{translations["checkout.parts"]}</p>
                    <a className={styles.remove} href="#" onClick={(e) => { removeAll(e, "item") }}>
                        {translations["checkout.emptyCartItems"]}
                    </a>
                </div>}
                {nonRemanItems.map((part) => (
                    <Item
                        key={part.code}
                        part={part}
                        onChange={onItemChangeClickHandler}
                        onRemove={onItemRemoveClickHandler}
                    />
                ))}
                {remanGroups.map(({ reman, deposit }) => (
                    <RemanufacturedItem
                        key={reman.code}
                        reman={reman}
                        deposit={deposit}
                        sparePartUrl={reman.url}
                        onChange={onRemanChangeClickHandler}
                        onRemove={onRemanRemoveClickHandler}
                    />
                ))}
                
                {cart.kits.length > 0 && <div className={styles.sectionWrapper}>
                    <p className={styles.heading}>{translations["checkout.kits"]}</p>
                    <a className={styles.remove} href="#" onClick={(e) => { removeAll(e, "kit")}}>
                        {translations["checkout.emptyCartKits"]}
                    </a>
                </div>}
                {cart.kits.map((kit) => (
                    <Kit
                        key={kit.bundleCode}
                        kitBundle={kit}
                        onChange={onKitChangeClickHandler}
                        onRemove={onKitRemoveClickHandler}
                    />
                ))}
            </div>
            {checkoutData.isRfqAllowed && 
                <div className={styles.content}>
                    <RFQNote rfqData={cart.rfq} onChange={reloadCart} />
                </div>
            }
            <div className={classnames(styles.content, styles.spacing)}>
                <div className={styles.summary}>
                    <div>
                        <p>{translations["checkout.numberOfArticles"]}</p>
                        <p>{cart.summary.lineItemsCount - cart.summary.depositItemsCount}</p>
                    </div>
                    <div>
                        <p>{translations["checkout.subtotal"]}</p>
                        <p>{cart.summary.listPricesTotal}</p>
                    </div>
                    {cart.summary.depositItemsCount > 0 && <div>
                        <p>{translations["checkout.depositTotal"]}</p>
                        <p>+{cart.summary.depositPricesTotal}</p>
                    </div>}
                    <div>
                        <p>{translations["checkout.yourDiscount"]}</p>
                        <p>-{cart.summary.discountTotal}</p>
                    </div>
                    <div>
                        <p>{translations["checkout.totalWithoutVatAndShipping"]}</p>
                        <p>{cart.summary.subTotal}</p>
                    </div>
                </div>
            </div>
            <div className={classnames(styles.content, styles.spacing)}>
                <DeliveryMethods deliveryMethodSubTypes={cart.deliveryMethodSubTypes} onValueChange={setDeliveryInfoValid} hasUsedParts={cart.lineItems.some(i => i.isUsedItem)} deliveryMethods={cart.deliveryMethods} onDeliveryChange={reloadCart} showFreightPopup={showFreightPopup} />
                {checkoutData.partialDeliveryAllowed && <DeliveryOptions hasMoreThanOneProduct={hasMoreThanOneProduct} initialDeliveryOption={cart.selectedDeliveryOption} fullDeliveryText={checkoutData.fullDeliveryDescription} partialDeliveryText={checkoutData.partialDeliveryDescription} onDeliveryChange={reloadCart} />}
                <RfqSplitOrder rfq={cart.rfq} onSplitOrderChange={reloadCart} />
            </div>
            <div className={classnames(styles.content, styles.spacing)}>
                <BillingInfo billingSummary={{ ...cart.customerInformation, emailRegex: checkoutData.emailRegex }} isReferenceFieldHidden={checkoutData.isReferenceFieldHidden} billingInfoChanged={(b) => { billingInfo = b }} />
            </div>
            <div className={styles.summaryContent}>
                {checkoutData.showAlternativeSummaryLines
                    ? <AlternativeSummaryLines styles={styles} translations={translations} cart={cart} showFreightPopup={showFreightPopup} />
                    : <SummaryLines styles={styles} translations={translations} cart={cart} showFreightPopup={showFreightPopup} />}
                {(noteExists || (cart.lineItems.flatMap(l => l.validationMessages).length > 0 || cart.validationMessages.length > 0)) && <div className={styles.validationMessages}>
                    {cart.lineItems.length > 0 &&
                        cart.lineItems.flatMap(l => l.validationMessages).map(
                            vm => (vm.redirectUri
                                ? <a href={vm.redirectUri}><div key={vm.message} className={styles.validationMessage} dangerouslySetInnerHTML={{ __html: vm.message }}></div></a>
                                : <div key={vm.message} className={styles.validationMessage} dangerouslySetInnerHTML={{ __html: vm.message }}></div>))}
                    {cart.validationMessages.length > 0 && cart.validationMessages.map(vm => (vm.redirectUri
                        ? <a href={vm.redirectUri}><div key={vm.message} className={styles.validationMessage} dangerouslySetInnerHTML={{ __html: vm.message }}></div></a>
                        : <div key={vm.message} className={styles.validationMessage} dangerouslySetInnerHTML={{ __html: vm.message }}></div>))}
                    {noteExists && <div className={classnames(styles.validationMessage, styles.validationInformation)} dangerouslySetInnerHTML={{ __html: translations["checkout.rfqNoteInformation"] }}></div>}
                </div>}
                {(cart.branchContactInfo.phone || cart.branchContactInfo.email) && <div className={styles.contactInfo}>
                    <div dangerouslySetInnerHTML={{ __html: translations["checkout.contactInfoLabel"] }}></div>
                    {cart.branchContactInfo.phone && <div className="font-weight-bold">{translations["checkout.contactInfoPhoneNo"]} {cart.branchContactInfo.phone}</div>}
                    {cart.branchContactInfo.email && <div className="font-weight-bold">{translations["checkout.contactInfoEmail"]} {cart.branchContactInfo.email}</div>}
                </div>
                }
                <div className={styles.acceptance}>
                    <div>
                        <input id="terms-and-conditions" type="checkbox" className={styles.checkboxFix} checked={termsAndConditionsValue} onChange={toggleValue}></input>
                        <label htmlFor="terms-and-conditions" className={styles.termsAndConditionsCheckbox}>
                            <small>
                                {translations["checkout.termsAndConditionsLabel"]} <a className={styles.termsAndConditions} href={checkoutData.termsAndConditionsLink}>{translations["checkout.termsAndConditionsLink"]}</a>
                            </small>
                        </label>
                    </div>
                    <a className="btn btn--primary" onClick={createOrder}>{willRfqBeCreated ? translations["checkout.placeRfq"] : translations["checkout.placeOrder"]}</a>
                </div>
            </div>
        </div>
    );
}

export default App;

function separateRemanGroups(lineItems: LineItem[]): [LineItem[], { reman: LineItem, deposit: LineItem }[]] {
    const [remanItems, otherItems] = partition(lineItems, item => item.isRemanItem);
    const depositCodesToReman = new Map(remanItems.map(item => [getDepositCode(item.code), item]));

    const [depositItems, normalItems] = partition(otherItems, item => depositCodesToReman.has(item.code));

    const remanDepositPairs = depositItems.map(deposit => ({ reman: depositCodesToReman.get(deposit.code)!, deposit }));

    return [
        normalItems,
        remanDepositPairs
    ]
}

function getDepositCode(remanCode: string): string {
    return remanCode.replace("VOE90", "VOE80");
}