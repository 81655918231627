import { useRef, useState } from "react";

export const useValidation = (message: string, regex?: RegExp, regexMessage?: string) => {
    const [errorMessages, setErrorMessages] = useState<Record<string, string | undefined>>({});
    const ids = useRef(new Set<string>());

    const getErrorMessage = prepareGetErrorMessage(message, regex, regexMessage);

    const f = (id: string) => {
        ids.current.add(id);
        return ({
            onBlur: (fe: React.FocusEvent<HTMLInputElement> | React.FocusEvent) => {
                setErrorMessages(getErrorMessage((fe.target as HTMLInputElement).value, errorMessages, id));
            },
            error: errorMessages[id]
        });
    }

    return Object.assign(f, {
        setErrorMessage: (id: string, error: string | undefined) => setErrorMessages({
            ...errorMessages,
            [id]: error
        }),
        hasAnyErrors: () => Object.values(errorMessages).some(e => !!e),
        revalidate: (data: Partial<Record<string, unknown>>) => {
            let erm = errorMessages;
            ids.current.forEach((name) => {
                erm = getErrorMessage(data[name] as string, erm, name);
            });
            setErrorMessages(erm);
            return !Object.values(erm).some(e => !!e);
        }
    });
}

const prepareGetErrorMessage = (message: string, regex: RegExp | undefined, regexMessage: string | undefined) => (value: string | null | undefined, errorMessages: Record<string, string | undefined>, id: string) => {
    if (!value) {
        return ({
            ...errorMessages,
            [id]: message
        });
    } else if ((!!regex && !regex.test(value))) {
        return ({
            ...errorMessages,
            [id]: regexMessage ?? message
        });
    } else {
        return ({
            ...errorMessages,
            [id]: undefined
        });
    }
}