import { useMemo, useState } from "react";
import ShoppingCart from "./Steps/ShoppingCart";
import UserInformation from "./Steps/UserInformation";
import DeliveryMethod from "./Steps/DeliveryMethod";
import * as styles from "./app.module.scss";
import { ButtonGroup, debounce, delay, updateMachineCart, useMachineCartChange, usePromise } from "ui";
import {
    MachineBranch,
    MachineCart,
    MachineCartDeliveryBranches,
    MachineDeliveryAddress,
    MessageRecord
} from "ui/src/types";
import OrderConfirmation from "./Steps/OrderConfirmation";

type CreateOrderResponse = {
    isSuccess: true
    orderConfirmationPageUrl: string;
    orderId: string;
} | {
    isSuccess: false
    cartApiModel: MachineCart;
};

const loggedIn = window.app.preloadState.isLoggedIn;

function App() {
    const translations = window.app.preloadState.translation;
    const [validationMessages, setValidationMessages] = useState<MessageRecord[]>([]);

    const [activeStep, setActiveStep] = useState(1);
    const [cartData, setCartData] = useState<MachineCart | null>(window.app.preloadState.machineSalesCheckout.cart);

    useMachineCartChange(cart => setCartData(cart));

    const goToStep = useMemo(() => debounce(async (step: number) => {
        window.scrollTo(0, 0);
        await updateMachineCart();
        setActiveStep(step);
    }, 100), []);

    const [deliveryMethodEnabled, setDeliveryMethodEnabled] = useState(false);
    const [orderConfirmationEnabled, setOrderConfirmationEnabled] = useState(false);

    const createOrder = async () => {
        await delay(500);
        await updateMachineCart();
        const result = await fetch(`/api/machinesales/order`, {
            method: 'POST',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        const data: CreateOrderResponse = await result.json();
        if (!data.isSuccess) {
            setValidationMessages(data.cartApiModel.validationMessages ?? []);
        } else {
            location.assign(data.orderConfirmationPageUrl);
        }
    }

    const { data: pickupBranches } = usePromise(async () => {
        const result = await fetch(`/api/machinesales/cart/delivery-method/get-branches`, {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        const data: MachineCartDeliveryBranches = await result.json();
        return data;
    }, []);

    const { data: deliveryAddresses } = usePromise(async () => {
        if (!loggedIn) return [];
        const result = await fetch(`/api/machinesales/cart/delivery-method/get-addresses`, {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        const data: MachineDeliveryAddress[] = await result.json();
        return data.map(a => ({ label: a.displayName, value: a.id }));
    }, []);

    return (
        <div className={styles.tab}>
            <div className={styles.tabHead} id="pdp-accordion-title">
                <ButtonGroup key={activeStep} initialSelected={activeStep} disabled={cartData === null} onChecked={goToStep} theme="Dark" items={[
                    { label: translations["machineSalesCheckout.navigationHeadingsCart"], value: 1 },
                    { label: translations["machineSalesCheckout.navigationHeadingsUserInformation"], value: 2 },
                    { label: translations["machineSalesCheckout.navigationHeadingsDeliveryMethod"], value: 3, isEnabled: deliveryMethodEnabled },
                    { label: translations["machineSalesCheckout.navigationHeadingsOrderConfirmation"], value: 4, isEnabled: orderConfirmationEnabled && deliveryMethodEnabled }
                ]} />
            </div>
            <div className={styles.tabContent}>
                {cartData !== null && <>
                    {activeStep == 1 && <ShoppingCart cart={cartData} proceed={goToStep.bind(undefined, 2)} goBack={() => history.back()} />}
                    {activeStep == 2 && <UserInformation cart={cartData} enableNextStep={setDeliveryMethodEnabled} proceed={goToStep.bind(undefined, 3)} goBack={goToStep.bind(undefined, 1)} />}
                    {activeStep == 3 && <DeliveryMethod cart={cartData} pickupBranches={pickupBranches || { branches: [], limitedAvailability: false }} deliveryAddresses={deliveryAddresses || []} enableNextStep={setOrderConfirmationEnabled} proceed={goToStep.bind(undefined, 4)} goBack={goToStep.bind(undefined, 2)} />}
                    {activeStep == 4 && <OrderConfirmation validationMessages={validationMessages} moveToUserInformation={goToStep.bind(undefined, 2)} pickupBranches={pickupBranches || { branches: [], limitedAvailability: false }} deliveryAddresses={deliveryAddresses || []} moveToDeliveryMethods={goToStep.bind(undefined, 3)} moveToCart={goToStep.bind(undefined, 1)} cart={cartData} proceed={createOrder} goBack={goToStep.bind(undefined, 3)} />}
                </>}
            </div>
        </div>
    );
}

export default App;
