import * as styles from "./app.module.scss";
import { popup, ButtonResult, ButtonType, PopupSize, FinancePopUpBody, useMachineCartItemUpdate, useMachineCartChange, updateMachineCartItem, updateMachineCart } from "ui";
import { UpdateFinanceOptions } from "./types";
import { MachinePaymentMode } from "ui/src/types";

const translations = (window as any).app.preloadState.translation;
const pdpData = window.app.preloadState.machinePdp;
const financingPopUpData = window.app.preloadState.financingPopUp;

function App() {
    const financeOptionsPopup = async () => {
        let financeOptions: UpdateFinanceOptions | null = null;
        
        const popUpResult = await popup(
            translations["financeOptions.header"],
            <FinancePopUpBody serialNumber={pdpData.itemNumber ?? ""} />,
            [],
            PopupSize.ExtraVertical,
            undefined,
            {
                content: <div className={styles.info}>
                    <div>
                        <span>{translations["financeOptions.email"]}: {financingPopUpData.financingPopUpEmailAddress}</span>
                    </div>
                    <div>
                        <span>{translations["financeOptions.phone"]}: {financingPopUpData.financingPopUpPhoneNumber}</span>
                    </div>
                </div>,
                reverse: true
            }
        );
    }

    return <a onClick={financeOptionsPopup} className="btn btn--outlined w-100">{translations["machinePdp.financeButtonLabel"]}</a>
}

export default App;
