import React from "react";
import { FacetValue, SparePartsList, SparePartsSortField } from "ui";

function App() {
    const state = (window as any).app.preloadState["sparePartVariationList"];
    const facets = (window as any).app.preloadState["sparePartVariationFacets"];
    const total = (window as any).app.preloadState["sparePartVariationTotal"];
    const code = (window as any).app.preloadState["sparePartVariationCode"];

    const callback = async (page: number, facets: FacetValue[], sort: SparePartsSortField, sortAscending: boolean) => {
        const facetString = facets.length > 0
            ? facets.flatMap(facet => `&${facet.key}=${facet.values.join(',')}`).join('')
            : "";
        const response = await fetch(`/api/spareparts/${code}?pageNumber=${page}${facetString}&sort=${sort}&sortDirection=${sortAscending ? 'asc' : 'desc'}`, {
            method: "GET",
            headers: {
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        return response.json();
    }

    return <SparePartsList items={state} facets={facets} total={total} pageCallback={callback} />
}

export default App;
