import { useMemo, useState } from "react";
import * as styles from "./InfoPopUpIcon.module.scss";
import cn from 'classnames';
import { ButtonResult, ButtonType, popup, PopupSize } from "../../utilities/popup";

type InfoPopUpIconProps = {
    body?: string | null;
    title?: string | null;
    additionalClass?: string | null;
};

const translations = window.app.preloadState.translation;

export const InfoPopUpIcon = ({ body, title, additionalClass }: InfoPopUpIconProps) => {

    if (body == null) return <></>;

    const showInfoPopup = async () => {
        const result = await popup(
            title != null ? title : "",
            body,
            [
                { label: translations["common.usedPartsPopUpButtonLabel"], result: ButtonResult.Ok, type: ButtonType.Primary }

            ],
            PopupSize.Small, 
            title != null ? "" : styles.noTitleInfoPopUp
        );
    }

    return <span className={cn(styles.infoIcon, additionalClass != null && additionalClass)} onClick={() => showInfoPopup()}></span>;
}
